@use '../../scss/' as *;

/* header
-------------------------------------------------------------- */
.header.main {
    background: transparent;
}

.header {
    position: absolute;
    width: 100%;
    z-index: 1000;
    height: 110px;
    background-color: transparent;
    @include flex(center,space-between);   
    #site-header-inner {
        @include flex(center,flex-start);
        width: $w100;
        .tf-button {
            margin-left: auto;
        }
        .no-margin {
            margin-left: unset;
        }
        img {

        }
    }
    #main-nav {
        position: absolute;
        left: 35%;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        padding-left: 10px;
        @include res1200 {
            left: 30%;
        }

        @include res991{
            background-color: #070a29;
            box-shadow: 0 3px 16px rgb(47 83 109 / 12%);
            height: 1000vh;
            left: 0;
            margin: 0 auto;
            position: absolute;
            top: 2px;
            -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
            transition: -webkit-transform .5s ease;
            transition: transform .5s ease;
            transition: transform .5s ease,-webkit-transform .5s ease;
            width: 250px;
            z-index: 999999;
            padding-left: 30px;

            .menu {
                align-items: flex-start;
                flex-direction: column;
                padding-left: 0;
                align-items: flex-start;

                li {
                    // border-top: 1px solid rgba(255,255,255,0.2); 
                }
                
            }

            .sub-menu li a {
                color: #fff;
            }

            &.active {
                transform: translateX(0);

                .sub-menu {
                    box-shadow: none;
                    opacity: 1;
                    position: relative;
                    visibility: visible;
                    transform: none !important;
                    background: transparent;
                    
                    display: none;

                   
                    &:hover {
                        transform: none ;
                    }
                    li {
                        border-bottom: 1px solid rgba(255,255,255,0.2); 
                    }
                }
                .menu {

                    li.active {
                        .sub-menu {
                            display: block;
                        } 
                    }
                }
            }
        }
    }
} 

#main-nav .menu {
    @include flex(center, flex-start);
    li {
        position: relative;
        margin-right: 22px;

        &.active > a {
            color: #86FF00;
        }
        
        a {
            @include font-16;
            font-family: $font-main;
            font-weight: bold;
            position: relative;
            display: block;
            color: $white;
            padding-right: 15px;
            padding-top: 20px;
            padding-bottom: 20px;    
            line-height: 1.5; 

            &.active {
                color: #86FF00;
            }
            
            &:hover {
                color: $color-hover;
            }           
        }
        &:hover .sub-menu{
            opacity: 1;
            visibility: visible;
            transform: translateY(5px);
        }
    }
    li.menu-item-has-children>a {
        &::after {
            @include transition3;
            position: absolute;
            font-family: "Font Awesome 5 Pro";
            content: '\f078';
            right: 0;
            top: 50%;
            font-weight: 400;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
            font-size: 12px;
            color: $white;
        }   
            
        &:hover::after,
        &:hover {
            color: $color-main1;
        }
    }

    li.menu-item.current-menu-item > a {
        color: $color-main1;
        &::after {
            color: $color-main1;
        }
    }
}

#main-nav2 {
    .menu {
        li {
            a {
                font-family: "Orbitron", sans-serif;
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                position: relative;
                display: block;
                color: #fff;
                padding-right: 18px;
                text-transform: capitalize;
                padding-top: 20px;
                padding-bottom: 20px;
                &:hover {
                    color: $color-hover;
                }
            }
        }
    }
}


/* Header Fixed
-------------------------------------------------------------- */
#header_main.is-fixed {
    @include transition5;
    z-index: 9999;

    position  : fixed;

    left      : 0;
    width     : 100%;
    background: #09121D;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), inset 0px 1px 0px #ECECEC;
    &.is-fixed.is-small {
        top: -1px;
        opacity: 1;
        visibility: visible;
        height: 80px;
    }
}

#header_main.main.is-fixed {
    background-color: #190d3e;
}

.header.main.is-fixed .tf-button span.tl::before, 
.header.main.is-fixed .tf-button span.tl::after {
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-bottom: 16px solid #190d40 !important;
}

/* menu
-------------------------------------------------------------- */
#main-nav-mobi {
    @include translateY0;
    display: block ;
    margin: 0 auto;
    width: 100%;
    left: 0;
    z-index: 9999;
    box-shadow: 0 0 50px 10px rgb(0 0 0 / 10%);
    -webkit-box-shadow: 0 0 50px 10px rgb(0 0 0 / 10%);
    -moz-box-shadow: 0 0 50px 10px rgba(0,0,0,.3);
    padding-left: 0;
    position: absolute;
    top: 100%;
    overflow-y: auto;
    background: $white;
    max-height: 60vh;
    ul {
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            margin: 0;
            cursor: pointer;
            padding: 0;
            border-top: 1px solid rgba(2,14,40,0.2);
            position: relative;
        }
        li >ul >li:first-child{
            border-top: 1px solid rgba(2,14,40,0.2);
        }
    }
    ul > li > a {
        @include transition3;
        display: inline-block;
        padding: 12px 20px;
        color: $bgr-body;
        position: relative;
    }
    .sub-menu li a {
        padding-left: 40px;
    }
    ul li:first-child {
        border-top: 0px;
    }
    ul ul li.current-item a,
    ul > li.current-menu-item > a,
    ul > li > a:hover {
    color: $color-hover;
    }

    .menu-item-has-children .arrow {
        @include transition3;
        cursor: pointer;
        display: inline-block;
        font-size: 20px;
        line-height: 48px;
        position: absolute;
        right: 15px;
        text-align: center;
        top: 2px;
        width: 48px;
    }
    .menu-item-has-children .arrow:before {
        font-family: "Font Awesome 5 Pro";
        content: "\f107";
        color: $bgr-body;
    }

    .menu-item-has-children.current-menu-item .arrow:before {
        color: $color-hover;
    }
}

/* Mobile Menu Button */
.mobile-button {
    @include transition3;
    display: none;
    position: absolute;
    width: 26px;
    height: 26px;
    top: 50%;
    background-color: transparent;
    cursor: pointer;
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%);
    transform: translateY(0%);
    &:before,
    &:after,
    span {
    background-color: #fff;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    transition: all ease 0.3s;
    }
    &:before,
    &:after {
    content: "";
    position: absolute;
    top: 0;
    height: 3px;
    width: 100%;
    left: 0;
    top: 50%;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    }
    span {
        position: absolute;
        width: 100%;
        height: 3px;
        left: 0;
        top: 50%;
        overflow: hidden;
        text-indent: 200%;
    }
    &:before {
        -webkit-transform: translate3d(0, -7px, 0);
        -moz-transform: translate3d(0, -7px, 0);
        transform: translate3d(0, -7px, 0);
    }
    &:after {
        -webkit-transform: translate3d(0, 7px, 0);
        -moz-transform: translate3d(0, 7px, 0);
        transform: translate3d(0, 7px, 0);
    }
    &.active span {
        opacity: 0;
    }
    &.active:before {
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        -moz-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
    }
    &.active:after {
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        -moz-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
    }

    @include res991 {
        display: block;
        position: relative;
        margin-left: 30px;
    }
}
/* Sub Menu */
#main-nav .sub-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 200px;
    background-color: $white;
    box-shadow: $box-shadow;
    border-radius: 2px;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transform: translateY(15px);
    -moz-transform: translateY(15px);
    -ms-transform: translateY(15px);
    -o-transform: translateY(15px);
    transform: translateY(15px);
    padding: 5px 0;

    @include res991 {
        background-color: transparent;
    }
    li {
        margin-right: 0;
        a {
            padding: 10px 20px;
            color: $bgr-body;
            opacity: 0.9;
        }
        &:hover .sub-menu {
            opacity: 1;
            visibility: visible;
            -webkit-transform: translateY(0);
            -moz-transform: translateY(0);
            -ms-transform: translateY(0);
            -o-transform: translateY(0);
            transform: translateY(0);
        }
    }
    li.current-item a{
        color: $color-main1;
        opacity: 1;
    }
}